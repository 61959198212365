export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Автоматизированный поиск госзакупок 44-ФЗ, 223-ФЗ и коммерческих тендеров. Анализ конкурентов, email-уведомления, 10 000+ новых закупок ежедневно. Бесплатный доступ на 7 дней. 8 800 250-13-40"},{"name":"og:site_name","content":"ТендерМонитор"},{"name":"og:title","content":"ТендерМонитор: автоматический поиск тендеров с аналитикой | 7 дней бесплатно"},{"name":"og:description","content":"Полный доступ к госзакупкам 44-ФЗ, 223-ФЗ и коммерческим тендерам. 7 дней — без оплаты!"},{"name":"og:type","content":"website"},{"name":"og:url","content":"https://tenmon.ru"},{"name":"og:image","content":"https://tenmon.ru/pics/landing/main-pic.png"},{"name":"twitter:title","content":"ТендерМонитор: поиск тендеров 7 дней бесплатно"},{"name":"twitter:description","content":"Автоматизированный мониторинг 44-ФЗ, 223-ФЗ и коммерческих закупок. Анализ конкурентов в 1 клик!"},{"name":"twitter:image","content":"https://tenmon.ru/pics/landing/main-pic.png"},{"name":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"ТендерМонитор: поиск тендеров 44-ФЗ и 223-ФЗ | 7 дней бесплатно","charset":"utf-8","viewport":"width=device-width, initial-scale=1","htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 1800000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false